// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

@for $i from 0 through 100 {
    #{'.w-' + $i} {
        width: #{$i + '%'} !important;
    }
    #{'.h-' + $i} {
        height: #{$i + '%'} !important;
    }
    #{'.p-' + $i} {
        padding: #{$i + 'px'} !important;
    }
    #{'.px-' + $i} {
        padding-right: #{$i + 'px'} !important;
        padding-left: #{$i + 'px'} !important;
    }
    #{'.py-' + $i} {
        padding-top: #{$i + 'px'} !important;
        padding-bottom: #{$i + 'px'} !important;
    }
    #{'.pr-' + $i} {
        padding-right: #{$i + 'px'} !important;
    }
    #{'.pl-' + $i} {
        padding-left: #{$i + 'px'} !important;
    }
    #{'.pt-' + $i} {
        padding-top: #{$i + 'px'} !important;
    }
    #{'.pb-' + $i} {
        padding-bottom: #{$i + 'px'} !important;
    }
    #{'.m-' + $i} {
        margin: #{$i + 'px'} !important;
    }
    #{'.mx-' + $i} {
        margin-right: #{$i + 'px'} !important;
        margin-left: #{$i + 'px'} !important;
    }
    #{'.my-' + $i} {
        margin-top: #{$i + 'px'} !important;
        margin-bottom: #{$i + 'px'} !important;
    }
    #{'.mr-' + $i} {
        margin-right: #{$i + 'px'} !important;
    }
    #{'.ml-' + $i} {
        margin-left: #{$i + 'px'} !important;
    }
    #{'.mt-' + $i} {
        margin-top: #{$i + 'px'} !important;
    }
    #{'.mb-' + $i} {
        margin-bottom: #{$i + 'px'} !important;
    }
}

img {
    max-width: 100%;
}

.rdw-editor-main {
    height: fit-content;
}

.image-input-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        border-radius: 12px;
        // max-width: 90%;
        max-height: 90%;
        border: 1px solid $grey300;
        max-width: 500px;
        width: 80%;
    }
}
.richtext-container .public-DraftEditor-content, .DraftEditor-editorContainer, .DraftEditor-root {
    height: fit-content !important;
}
.save-button {
    width: fit-content !important;
}
.modal-content {
    position: absolute;
    outline: none !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: $grey100;
    border: 2px solid $grey200;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    padding: 24px;
    &.add-modal {
        width: 600px;
    }
    h2 {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.6;
        letter-spacing: 0.0075em;
        color: $grey900;
    }
    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        color: $grey900;
        margin: 0;
    }
}
.passive-content {
    background-color: $grey200;
}
textarea {
    border-bottom: 8px solid #fff !important;
    border-top: 8px solid #fff !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
a.MuiLink-button {
    color: $secondaryMain !important;
    text-decoration: none !important;
}
.color-palette-body {
    width: fit-content;
    display: flex;
    align-items: center;
    label {
        margin-right: 12px;
        color: $grey600;
        min-width: 80px;
    }
}
.dv-list {
    li {
        border: 1px solid #ddd;
        padding: 4px 12px;
        border-radius: 10px;
        user-select: none;
        cursor: grab;
    }
    li:hover {
        background-color: $grey100;
    }
    li.passive {
        background-color: $grey200;
    }
}
.dv-color-list {
    li {
        padding: 4px 12px;
    }
}
.dv-switcher.loading {
    cursor: wait !important;
}
.dv-list-image {
    max-height: 90px;
}
.us-list {
    display: flex;
    .button-area {
        margin-left: auto;
    }
    .MuiTypography-root {
        margin-left: 16px;
    }
    img {
        border-radius: 50%;
        height: 72px;
        aspect-ratio: 1;
    }
}
.switch-label {
    display: flex;
    align-items: center;
}
.homepage-side {
    margin-bottom: 8px;
    margin-top: 4px;
    width: 100%;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &.active {
        max-height: 300px;
    }
}
.internship-ul {
    padding-left: 8px;
    list-style: none;
    li {
        display: flex;
        font-size: 16px;
        .title {
            width: 40%;
            font-weight: 800;
        }
        .explain {
            width: 60%;
            
        }
    }
}

.richtext-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
        width: 100%;
        margin: 8px 0 16px;
        padding-left: 4px;
    }
    .public-DraftEditor-content {
        width: 100%;
        border-radius: 12px;
        border: 1px solid #c4c4c4;
        min-height: 180px;
        margin-top: 4px;
        padding: 12px;
    }
    .richtext-toolbar {
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
        button {
            margin: 0 4px;
        }
    }
}